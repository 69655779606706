import * as React from "react"


// markup
const IndexPage = () => {
  return (
    <main>
      <title>PannTech Home Page</title>
      <img style={{width: "30%", height: "auto"}} src="https://pannunziotech.files.wordpress.com/2019/05/cropped-panntech-logo-no-background-3.png"></img>
      <h1 style={{"text-align": "center"}}>Lawnmower, Bicycle, and Small Engine Service and Repair</h1>
      <div style={{"align":"center","text-align":"center"}}>
        <iframe style={{"align":"center","text-align":"center"}} src="https://docs.google.com/forms/d/e/1FAIpQLSfXgRH6n_35cEu17zIZ3sHlloUdfpdynfoP0KpYAiEy1k6VFg/viewform?embedded=true" width="640" height="1448" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
    </main>

    )
}

export default IndexPage
